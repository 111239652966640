.device-wrapper {
    z-index: 999;
    position: relative;
}

.device {
    border-radius: 3rem;

    .screen {
        overflow: hidden;
    }
}


.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] {
    padding-bottom: 202.898072%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .screen {
    top: 2.185596%;
    left: 5.5%;
    width: 90.225895%;
    height: 93.628809%;
}


.owl-carousel {
    .owl-dots {
        margin-top: 0 !important;
        .owl-dot {
            span {
                margin: 0 0.35rem !important;
            }

            &.active {
                span {
                    background: $brand-primary;
                }
            }
        }
    }
}



.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 202.898072%;
  background-image: url(../img/device-mockups/iPhoneX/portrait.png) !important;
  z-index: initial;
}
  .device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] .screen {
      top: 15px;
      left: 5%;
      width: 90%;
      height: 93.49030471%;
      border: 0px;
      z-index: -1;
      overflow: hidden;
      overflow: hidden;
  }
