/*
=========================================
=========================================

   Nova | Bootstrap app landing

=========================================
========================================= */
/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/
/*
   Icons
   ------------------------
*/
.icon-sm {
  width: 40px !important;
  height: 40px !important;
}

.icon-md {
  width: 60px !important;
  height: 60px !important;
}

.icon-lg {
  width: 80px !important;
  height: 80px !important;
}

.icon-xl {
  width: 120px !important;
  height: 120px !important;
}

/*
   Text utils
   ------------------------
*/
.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  letter-spacing: .1em;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.25rem;
}

.text-shadow {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
  text-shadow: 2px 2px 2px rgba(89, 84, 203, 0.1);
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-inherit {
  color: inherit;
}

/*
    Rounded corners - lg, sm
   ------------------------
*/
.rounded-lg {
  border-radius: 0.85rem !important;
}

.rounded-sm {
  border-radius: 0.2rem;
}

.rounded-xl {
  border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
/*
    Opacity helpers - .opacity-[1-9]
    ------------------------
*/
.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

/*
    Z-index helpers - .z-index-[10-50]
    ------------------------
*/
.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.z-index-30 {
  z-index: 30;
}

.z-index-40 {
  z-index: 40;
}

.z-index-50 {
  z-index: 50;
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/
.letter-spacing-0 {
  letter-spacing: 0 !important;
}

.letter-spacing-1 {
  letter-spacing: 0.1em;
}

.letter-spacing-2 {
  letter-spacing: 0.2em;
}

.letter-spacing-3 {
  letter-spacing: 0.3em;
}

.letter-spacing-4 {
  letter-spacing: 0.4em;
}

.letter-spacing-5 {
  letter-spacing: 0.5em;
}

/*
     Colours and backgrounds
    ------------------------
*/
.text-hover-primary {
  transition: all 0.2s ease-in-out;
}

.text-hover-primary:focus, .text-hover-primary:hover {
  color: #5954cb !important;
}

a.text-hover-primary {
  transition: all 0.2s ease-in-out;
}

a.text-hover-primary:focus, a.text-hover-primary:hover {
  color: #5954cb !important;
}

.bg-primary-light {
  background-color: #ebeaf9;
}

.border-primary {
  border-color: #5954cb !important;
}

.text-hover-secondary {
  transition: all 0.2s ease-in-out;
}

.text-hover-secondary:focus, .text-hover-secondary:hover {
  color: #6c757d !important;
}

a.text-hover-secondary {
  transition: all 0.2s ease-in-out;
}

a.text-hover-secondary:focus, a.text-hover-secondary:hover {
  color: #6c757d !important;
}

.bg-secondary-light {
  background-color: #edeeef;
}

.border-secondary {
  border-color: #6c757d !important;
}

.text-hover-success {
  transition: all 0.2s ease-in-out;
}

.text-hover-success:focus, .text-hover-success:hover {
  color: #28a745 !important;
}

a.text-hover-success {
  transition: all 0.2s ease-in-out;
}

a.text-hover-success:focus, a.text-hover-success:hover {
  color: #28a745 !important;
}

.bg-success-light {
  background-color: #e5f4e9;
}

.border-success {
  border-color: #28a745 !important;
}

.text-hover-info {
  transition: all 0.2s ease-in-out;
}

.text-hover-info:focus, .text-hover-info:hover {
  color: #17a2b8 !important;
}

a.text-hover-info {
  transition: all 0.2s ease-in-out;
}

a.text-hover-info:focus, a.text-hover-info:hover {
  color: #17a2b8 !important;
}

.bg-info-light {
  background-color: #e3f4f6;
}

.border-info {
  border-color: #17a2b8 !important;
}

.text-hover-warning {
  transition: all 0.2s ease-in-out;
}

.text-hover-warning:focus, .text-hover-warning:hover {
  color: #ffc107 !important;
}

a.text-hover-warning {
  transition: all 0.2s ease-in-out;
}

a.text-hover-warning:focus, a.text-hover-warning:hover {
  color: #ffc107 !important;
}

.bg-warning-light {
  background-color: #fff8e1;
}

.border-warning {
  border-color: #ffc107 !important;
}

.text-hover-danger {
  transition: all 0.2s ease-in-out;
}

.text-hover-danger:focus, .text-hover-danger:hover {
  color: #dc3545 !important;
}

a.text-hover-danger {
  transition: all 0.2s ease-in-out;
}

a.text-hover-danger:focus, a.text-hover-danger:hover {
  color: #dc3545 !important;
}

.bg-danger-light {
  background-color: #fbe7e9;
}

.border-danger {
  border-color: #dc3545 !important;
}

.text-hover-light {
  transition: all 0.2s ease-in-out;
}

.text-hover-light:focus, .text-hover-light:hover {
  color: #F7FAFF !important;
}

a.text-hover-light {
  transition: all 0.2s ease-in-out;
}

a.text-hover-light:focus, a.text-hover-light:hover {
  color: #F7FAFF !important;
}

.bg-light-light {
  background-color: #fefeff;
}

.border-light {
  border-color: #F7FAFF !important;
}

.text-hover-dark {
  transition: all 0.2s ease-in-out;
}

.text-hover-dark:focus, .text-hover-dark:hover {
  color: #343a40 !important;
}

a.text-hover-dark {
  transition: all 0.2s ease-in-out;
}

a.text-hover-dark:focus, a.text-hover-dark:hover {
  color: #343a40 !important;
}

.bg-dark-light {
  background-color: #e7e7e8;
}

.border-dark {
  border-color: #343a40 !important;
}

.bg-gray-100 {
  background: #F7FAFF !important;
}

.text-gray-100 {
  color: #F7FAFF;
}

.bg-gray-200 {
  background: #e9ecef !important;
}

.text-gray-200 {
  color: #e9ecef;
}

.bg-gray-300 {
  background: #dee2e6 !important;
}

.text-gray-300 {
  color: #dee2e6;
}

.bg-gray-400 {
  background: #ced4da !important;
}

.text-gray-400 {
  color: #ced4da;
}

.bg-gray-500 {
  background: #adb5bd !important;
}

.text-gray-500 {
  color: #adb5bd;
}

.bg-gray-600 {
  background: #6c757d !important;
}

.text-gray-600 {
  color: #6c757d;
}

.bg-gray-700 {
  background: #495057 !important;
}

.text-gray-700 {
  color: #495057;
}

.bg-gray-800 {
  background: #343a40 !important;
}

.text-gray-800 {
  color: #343a40;
}

.bg-gray-900 {
  background: #212529 !important;
}

.text-gray-900 {
  color: #212529;
}

.badge-primary-light {
  color: #5954cb;
  background-color: #ebeaf9;
}

.badge-secondary-light {
  color: #6c757d;
  background-color: #edeeef;
}

.badge-success-light {
  color: #28a745;
  background-color: #e5f4e9;
}

.badge-info-light {
  color: #17a2b8;
  background-color: #e3f4f6;
}

.badge-warning-light {
  color: #ffc107;
  background-color: #fff8e1;
}

.badge-danger-light {
  color: #dc3545;
  background-color: #fbe7e9;
}

.badge-light-light {
  color: #F7FAFF;
  background-color: #fefeff;
}

.badge-dark-light {
  color: #343a40;
  background-color: #e7e7e8;
}

/*
     Transparent overlays
    ------------------------
*/
.overlay-content {
  position: relative;
  z-index: 20;
}

@media (min-width: 576px) {
  .light-overlay-sm,
  .overlay-hover-light-sm {
    position: relative;
  }
  .light-overlay-sm::after,
  .overlay-hover-light-sm::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 576px) {
  .overlay-hover-light-sm::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 576px) {
  .light-overlay-sm::after,
  .overlay-hover-light-sm:hover::after {
    opacity: .4;
  }
}

@media (min-width: 576px) {
  .light-overlay-sm-0::after,
  .overlay-hover-light-sm-0:hover::after {
    display: none;
  }
}

.dark-overlay-sm {
  position: relative;
}

@media (min-width: 576px) {
  .dark-overlay-sm::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .4;
    background: #000;
  }
}

@media (min-width: 576px) {
  .dark-overlay-sm-0::after {
    display: none;
  }
}

@media (min-width: 768px) {
  .light-overlay-md,
  .overlay-hover-light-md {
    position: relative;
  }
  .light-overlay-md::after,
  .overlay-hover-light-md::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 768px) {
  .overlay-hover-light-md::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .light-overlay-md::after,
  .overlay-hover-light-md:hover::after {
    opacity: .4;
  }
}

@media (min-width: 768px) {
  .light-overlay-md-0::after,
  .overlay-hover-light-md-0:hover::after {
    display: none;
  }
}

.dark-overlay-md {
  position: relative;
}

@media (min-width: 768px) {
  .dark-overlay-md::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .4;
    background: #000;
  }
}

@media (min-width: 768px) {
  .dark-overlay-md-0::after {
    display: none;
  }
}

@media (min-width: 992px) {
  .light-overlay-lg,
  .overlay-hover-light-lg {
    position: relative;
  }
  .light-overlay-lg::after,
  .overlay-hover-light-lg::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 992px) {
  .overlay-hover-light-lg::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 992px) {
  .light-overlay-lg::after,
  .overlay-hover-light-lg:hover::after {
    opacity: .4;
  }
}

@media (min-width: 992px) {
  .light-overlay-lg-0::after,
  .overlay-hover-light-lg-0:hover::after {
    display: none;
  }
}

.dark-overlay-lg {
  position: relative;
}

@media (min-width: 992px) {
  .dark-overlay-lg::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .4;
    background: #000;
  }
}

@media (min-width: 992px) {
  .dark-overlay-lg-0::after {
    display: none;
  }
}

@media (min-width: 1200px) {
  .light-overlay-xl,
  .overlay-hover-light-xl {
    position: relative;
  }
  .light-overlay-xl::after,
  .overlay-hover-light-xl::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 1200px) {
  .overlay-hover-light-xl::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 1200px) {
  .light-overlay-xl::after,
  .overlay-hover-light-xl:hover::after {
    opacity: .4;
  }
}

@media (min-width: 1200px) {
  .light-overlay-xl-0::after,
  .overlay-hover-light-xl-0:hover::after {
    display: none;
  }
}

.dark-overlay-xl {
  position: relative;
}

@media (min-width: 1200px) {
  .dark-overlay-xl::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .4;
    background: #000;
  }
}

@media (min-width: 1200px) {
  .dark-overlay-xl-0::after {
    display: none;
  }
}

.light-overlay,
.overlay-hover-light {
  position: relative;
}

.light-overlay::after,
.overlay-hover-light::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  background: #fff;
}

.overlay-hover-light::after {
  transition: opacity 0.15s linear;
  opacity: 0;
}

.light-overlay::after,
.overlay-hover-light:hover::after {
  opacity: .4;
}

.light-overlay-0::after,
.overlay-hover-light-0:hover::after {
  display: none;
}

.dark-overlay {
  position: relative;
}

.dark-overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  opacity: .4;
  background: #000;
}

.dark-overlay-0::after {
  display: none;
}

/*
     Other
    ------------------------
*/
.overflow-visible {
  overflow: visible !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.text-small {
  font-size: 0.85rem !important;
}

.text-lg {
  font-size: 3.5rem !important;
}

.text-gray {
  color: #b4b4b4 !important;
}

.text-muted {
  color: #777 !important;
}

.border-gray {
  border-color: #eee !important;
}

.index-forward {
  z-index: 99;
  position: relative;
}

.bg-cover {
  background-size: cover !important;
}

.bg-center {
  background-position: center center !important;
}

.bg-top {
  background-position: top center !important;
}

.bg-bottom {
  background-position: bottom center !important;
}

.line-height-sm {
  line-height: 1.3 !important;
}

.dotted-separator {
  border-top: 2px dotted rgba(0, 0, 0, 0.2);
}

.font-weight-light {
  font-weight: 400 !important;
}

.font-weight-normal {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 800 !important;
}

.accordion .card {
  border: none;
}

.accordion .card-header {
  background: #fff;
  border: none;
}

.accordion .card .btn-link {
  width: 100%;
  text-decoration: none;
  color: #333;
  text-align: left;
}

.accordion .card .btn-link:focus {
  box-shadow: none;
}

.border-md {
  border-width: 2px !important;
}

.border-lg {
  border-width: 4px !important;
}

.lined {
  display: inline-block;
  position: relative;
  padding-bottom: 0.7rem;
}

.lined::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3rem;
  height: 2px;
  background: #5954cb;
}

.lined::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 3.3rem;
  width: 0.7rem;
  height: 2px;
  background: #5954cb;
}

.lined-center::before {
  left: calc(50% - 1.65rem);
}

.lined-center::after {
  left: calc(50% + 1.65rem);
}

input.text-white::placeholder {
  color: #fff;
}

.bg-none {
  background: none !important;
}

.text-shadow {
  text-transform: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

/*
* ===================================================
*    Custom Orion Icons
* ===================================================
*/
.svg-icon {
  width: 32px;
  height: 32px;
  vertical-align: text-bottom;
  stroke: currentColor;
  stroke-width: 3;
  --layer1: currentColor;
  --layer2: currentColor;
  --layer3: currentColor;
}

.svg-icon.svg-icon-light {
  stroke-width: 2;
}

.svg-icon.svg-icon-heavy {
  stroke-width: 4;
}

.svg-icon-big {
  width: 64px;
  height: 64px;
}

/*
*
* =====================
* GENERAL
* =====================
*
*/
.text-small {
  font-size: 0.9rem !important;
}

section {
  position: relative;
  padding: 6.5rem 0;
}

.with-pattern-1::before {
  content: '';
  position: absolute;
  background: url(../img/pattern.png);
  width: 265px;
  height: 325px;
  right: 0px;
  top: 0px;
  background-repeat: no-repeat;
  z-index: 1;
}

.section-padding {
  padding: 6.5rem 0;
}

.section-padding-y {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.navbar {
  transition: all 0.3s;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.navbar.active {
  background: #fff;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .navbar {
    background: #fff;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
}

.navbar-toggler {
  color: #17141f;
}

.card.with-pattern {
  position: relative;
}

.card.with-pattern::before {
  content: '';
  background: url(../img/pattern-2.png) no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  border-radius: 0.35rem;
}

.testimonial-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: calc(-3rem - 50px);
  left: calc(50% - 50px);
}

.testimonial-img::after {
  content: '\f10e';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #5954cb;
  border: 3px solid #fff;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  color: #fff;
  text-align: center;
  line-height: 35px;
  font-size: 0.8rem;
}

.footer-link {
  color: #777;
  transition: all 0.3s;
}

.footer-link:hover, .footer-link:focus {
  color: #5954cb;
  text-decoration: none;
}

.list-check {
  list-style: none;
  padding-left: 0;
}

.list-check li {
  position: relative;
  padding-left: 1.5rem;
}

.list-check li::before {
  content: '\f00c';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #5954cb;
}

.social-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  transition: all 0.3s;
}

.social-link:hover, .social-link:focus {
  background: #5954cb;
  color: #fff;
  text-decoration: none;
  border-color: #5954cb;
}

.scropll-top-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #5954cb;
  border: 3px solid #cbc9ef;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: -3rem;
  right: 1rem;
  z-index: 999;
  color: #fff;
  transition: all 0.4s;
}

.scropll-top-btn.active {
  bottom: 1rem;
}

.scropll-top-btn:hover, .scropll-top-btn:focus {
  color: #fff;
  text-decoration: none;
  background: #3c37b5;
}

.device-wrapper {
  z-index: 999;
  position: relative;
}

.device {
  border-radius: 3rem;
}

.device .screen {
  overflow: hidden;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] {
  padding-bottom: 202.898072%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .screen {
  top: 2.185596%;
  left: 5.5%;
  width: 90.225895%;
  height: 93.628809%;
}

.owl-carousel .owl-dots {
  margin-top: 0 !important;
}

.owl-carousel .owl-dots .owl-dot span {
  margin: 0 0.35rem !important;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #5954cb;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 202.898072%;
  background-image: url(../img/device-mockups/iPhoneX/portrait.png) !important;
  z-index: initial;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] .screen {
  top: 15px;
  left: 5%;
  width: 90%;
  height: 93.49030471%;
  border: 0px;
  z-index: -1;
  overflow: hidden;
  overflow: hidden;
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 120px;
  left: 0px;
  border-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 160px;
  left: 0;
  background: #fff;
  border: solid 1px #ced4da;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* =========================================

      THEMING OF THE BOOTSTRAP COMPONENTS

   =========================================

    1 - NAVBAR
    2 - BUTTONS
    3 - TYPE
    4 - PAGINATION
    5 - UTILITIES
    6 - FORMS
    7 - CODE
    8 - NAV
    9 - CARD
    10 - DROPDOWNS

*/
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 600;
  border: 1px solid transparent;
  padding: 0.4rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:focus, .btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

.btn-link {
  font-weight: 600;
  color: #5954cb;
}

.btn-link:hover {
  color: #3631a1;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-primary {
  color: #fff;
  background-color: #5954cb;
  border-color: #5954cb;
}

.btn-primary:hover {
  color: #fff;
  background-color: #403abf;
  border-color: #3c37b5;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #5954cb;
  border-color: #5954cb;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3c37b5;
  border-color: #3934ab;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #F7FAFF;
  border-color: #F7FAFF;
}

.btn-light:hover {
  color: #212529;
  background-color: #d1e2ff;
  border-color: #c4daff;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #F7FAFF;
  border-color: #F7FAFF;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #c4daff;
  border-color: #b7d2ff;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #5954cb;
  background-color: transparent;
  background-image: none;
  border-color: #5954cb;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #5954cb;
  border-color: #5954cb;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #5954cb;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5954cb;
  border-color: #5954cb;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #F7FAFF;
  background-color: transparent;
  background-image: none;
  border-color: #F7FAFF;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #F7FAFF;
  border-color: #F7FAFF;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #F7FAFF;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #F7FAFF;
  border-color: #F7FAFF;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/*
 * 3. TYPE
 */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #17141f;
  background-color: #fff;
}

a {
  color: #5954cb;
  text-decoration: none;
}

a:hover, a:focus {
  color: #3631a1;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.4rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 600;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  border-left: 5px solid #5954cb;
}

.blockquote-footer {
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #5954cb !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #3c37b5 !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #5954cb;
  border-color: #5954cb;
}

.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #5954cb;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #3631a1;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.25);
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/*
* 5. UTILITIES
*/
.bg-primary {
  background-color: #5954cb !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3c37b5 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #F7FAFF !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c4daff !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.border-primary {
  border-color: #5954cb !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #F7FAFF !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-primary {
  color: #5954cb !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #3c37b5 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #F7FAFF !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c4daff !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.badge-primary {
  color: #fff;
  background-color: #5954cb;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3c37b5;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #F7FAFF;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #c4daff;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.08) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/*
  * 6. FORMS
  */
.form-control {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #b8b6e9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 84, 203, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(3.5rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #5954cb;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(89, 84, 203, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #deddf5;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5954cb;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #5954cb;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(89, 84, 203, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(89, 84, 203, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5954cb;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(89, 84, 203, 0.5);
}

/*
* 7.CODE
*/
code {
  font-size: 87.5%;
  color: #e83e8c;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #17141f;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-link.active {
  color: #5954cb;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5954cb;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #F7FAFF;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
}

.card-img-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}

/*
* 10. DROPDOWNS
*/
.dropdown-menu {
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #17141f;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
  font-weight: 600;
  color: #212529;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #F7FAFF;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #5954cb;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
}
