/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Icons
   ------------------------
*/
.icon-sm {
    width: 40px !important;
    height: 40px !important;
}

.icon-md {
    width: 60px !important;
    height: 60px !important;
}

.icon-lg {
    width: 80px !important;
    height: 80px !important;
}

.icon-xl {
    width: 120px !important;
    height: 120px !important;
}




/*
   Text utils
   ------------------------
*/

.text-uppercase {
    @include uppercase;
    letter-spacing: .1em;
}

// .text-xs {
//     font-size: $font-size-xs;
// }

.text-sm {
    font-size: $font-size-sm;
}

.text-base {
    font-size: $font-size-base;
}

.text-lg {
    font-size: $font-size-lg;
}

// .text-xl {
//     font-size: $font-size-xl;
// }

// .text-serif {
//     font-family: $font-family-serif !important;
// }

.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
    text-shadow: 2px 2px 2px rgba(theme-color('primary'), .1);
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-inherit {
    color: inherit;
}

/*
    Rounded corners - lg, sm
   ------------------------
*/

.rounded-lg {
    border-radius: $border-radius-lg + 0.5 !important;
}

.rounded-sm {
    border-radius: $border-radius-sm;
}

.rounded-xl {
    border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
// @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//         $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//
//         .border#{$infix} {
//             border: $border-width solid $border-color !important;
//         }
//     }
// }

/*
    Opacity helpers - .opacity-[1-9]
    ------------------------
*/


$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
    .opacity-#{$current-opacity} {
        opacity: #{$current-opacity/10};
    }
}

/*
    Z-index helpers - .z-index-[10-50]
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
    .z-index-#{$current-z-index} {
        z-index: #{$current-z-index};
    }
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/

.letter-spacing-0 {
    letter-spacing: 0 !important;
}

$spacing: .1em .2em .3em .4em .5em;

@each $current-spacing in $spacing {
    $i: index($spacing, $current-spacing);

    .letter-spacing-#{$i} {
        letter-spacing: #{$current-spacing};
    }
}

/*
     Colours and backgrounds
    ------------------------
*/


@each $color,
$value in $theme-colors {
    .text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    a.text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    .bg-#{$color}-light {
        background-color: theme-color-level($color, -11);
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color,
$value in $grays {
    .bg-gray-#{$color} {
        background: $value !important;
    }

    .text-gray-#{$color} {
        color: $value;
    }
}

// .bg-primary-100 {
//     background: $primary-100;
// }

@each $color,
$value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: theme-color-level($color, -11);
    }
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
    position: relative;
    z-index: 20;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                background: #fff;
            }
        }
    }

    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            &::after {
                transition: $transition-fade;
                opacity: 0;
            }
        }
    }

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix}:hover {
        @include media-breakpoint-up($next) {
            &::after {
                opacity: .4;
            }
        }
    }

    .light-overlay#{$infix}-0,
    .overlay-hover-light#{$infix}-0:hover {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .dark-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .4;
                background: #000;
            }
        }
    }

    .dark-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }
}

/*
     Other
    ------------------------
*/

.overflow-visible {
    overflow: visible !important;
}

.shadow-0 {
    box-shadow: none !important;
}

.text-small {
    font-size: 0.85rem !important;
}

.text-lg {
    font-size: 3.5rem !important;
}

.text-gray {
    color: #b4b4b4 !important;
}

.text-muted {
    color: $text-muted !important;
}

.border-gray {
    border-color: #eee !important;
}

.index-forward {
    z-index: 99;
    position: relative;
}

.bg-cover {
    background-size: cover !important;
}

.bg-center {
    background-position: center center !important;
}

.bg-top {
    background-position: top center !important;
}

.bg-bottom {
    background-position: bottom center !important;
}

.line-height-sm {
    line-height: 1.3 !important;
}

.dotted-separator {
    border-top: 2px dotted rgba(0, 0, 0, 0.2);
}

.font-weight-light {
    font-weight: $font-weight-light !important;
}
.font-weight-normal {
    font-weight: $font-weight-normal !important;
}
.font-weight-bold {
    font-weight: $font-weight-bold !important;
}

.accordion {
    .card {
        border: none;
        &-header {
            background: #fff;
            border: none;
        }

        .btn-link {
            width: 100%;
            text-decoration: none;
            color: #333;
            text-align: left;

            &:focus {
                box-shadow: none
            }
        }
    }
}

.border-md {
    border-width: 2px !important;
}

.border-lg {
    border-width: 4px !important;
}

.lined {
    display: inline-block;
    position: relative;
    padding-bottom: 0.7rem;
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3rem;
        height: 2px;
        background: $brand-primary;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 3.3rem;
        width: 0.7rem;
        height: 2px;
        background: $brand-primary;
    }

    &-center {
        &::before {
            left: calc(50% - 1.65rem);
        }

        &::after {
            left: calc(50% + 1.65rem);
        }
    }
}

input.text-white {
    &::placeholder {
        color: #fff;
    }
}

.bg-none {
    background: none !important;
}


.text-shadow {
    text-transform: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
