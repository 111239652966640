/*
*
* =====================
* GENERAL
* =====================
*
*/

.text-small {
    font-size: 0.9rem !important;
}

section {
    position: relative;
    padding: 6.5rem 0;
}

.with-pattern-1 {
    &::before {
        content: '';
        position: absolute;
        background: url(../img/pattern.png);
        width: 265px;
        height: 325px;
        right: 0px;
        top: 0px;
        background-repeat: no-repeat;
        z-index: 1;
    }
}


.section-padding {
    padding: 6.5rem 0;
}

.section-padding-y {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
}


.navbar {
    transition: all 0.3s;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;

    &.active {
        background: #fff;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    }

    @include media-breakpoint-down(md) {
        background: #fff;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    }
}

.navbar-toggler {
    color: $body-color;
}

.card.with-pattern {
    position: relative;
    &::before {
        content: '';
        background: url(../img/pattern-2.png) no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.5;
        border-radius: $border-radius-lg;
        // z-index: -1;
    }
}


.testimonial-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: calc(-3rem - 50px);
    left: calc(50% - 50px);

    &::after {
        content: '\f10e';
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $brand-primary;
        border: 3px solid #fff;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        color: #fff;
        text-align: center;
        line-height: 35px;
        font-size: 0.8rem;
    }
}

.footer-link {
    color: #777;
    transition: all 0.3s;

    &:hover, &:focus {
        color: $brand-primary;
        text-decoration: none;
    }
}


.list-check {
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        padding-left: 1.5rem;

        &::before {
            content: '\f00c';
            font-family: 'Font Awesome\ 5 Free';
            font-weight: 900;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $brand-primary;
            // margin-right: 0.5rem;
        }
    }
}


.social-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    transition: all 0.3s;

    &:hover, &:focus {
        background: $brand-primary;
        color: #fff;
        text-decoration: none;
        border-color: $brand-primary;
    }
}


.scropll-top-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $brand-primary;
    border: 3px solid lighten($brand-primary, 30%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: -3rem;
    right: 1rem;
    z-index: 999;
    color: #fff;
    transition: all 0.4s;
    // display: none;

    &.active {
        bottom: 1rem;
    }

    &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        background: darken($brand-primary, 10%);
    }
}
